const Footer = () => {
  return (
    <>
      <footer>
        <div className='footer-top-area theme-bg pt-50 pb-50'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 mb-30'>
                <div className='footer-logo f-left'>
                  <a href='index.html'>
                    <img
                      src='../assets/img/logo/community-logo-white-new.png'
                      alt=''
                    />
                  </a>
                </div>
                <div className='footer-top-text'>
                  <p>Copyright & Design By</p>
                  <h5>CPF (Community Promoting Foundation) - 2023</h5>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 mb-30'>
                <div className='footer-icon text-md-right'>
                  <a href='#'>
                    <i className='fab fa-facebook-f'></i>
                  </a>
                  <a href='#'>
                    <i className='fab fa-twitter'></i>
                  </a>
                  <a href='#'>
                    <i className='fab fa-behance'></i>
                  </a>
                  <a href='#'>
                    <i className='fab fa-youtube'></i>
                  </a>
                  <a href='#'>
                    <i className='fab fa-linkedin'></i>
                  </a>
                </div>
              </div>
            </div>
            <div className='footer-middle-area mt-20 pt-80'>
              <div className='row'>
                <div className='col-xl-5 col-lg-7 col-md-7 mb-30'>
                  <div className='footer-wrapper'>
                    <h3 className='footer-title'>Quick Links</h3>
                    <div className='footer-link'>
                      <ul>
                        <li>
                          <a href='/our-plans'>Our Plans </a>
                        </li>
                        <li>
                          <a href='/vision-mission'>Vision & Mission</a>
                        </li>
                        <li>
                          <a href='/team'>Our Team</a>
                        </li>
                        <li>
                          <a href='/our-journey'>Our Journey</a>
                        </li>
                        <li>
                          <a href='/objective'>Objective</a>
                        </li>
                      </ul>
                      <ul className='pl-40'>
                        {/* <li>
                          <a href='/refund-policy'>Refund Policy</a>
                        </li>
                        <li>
                          <a href='/refund-policy'>Refund Policy</a>
                        </li> */}
                        {/* <li>
                          <a href='/campaign'>Campaign </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 col-lg-5 col-md-5 mb-30'>
                  <div className='footer-wrapper'>
                    <h3 className='footer-title'>Contact Us</h3>
                    <ul className='footer-address'>
                      <li>
                        <div className='footer-address-icon f-left'>
                          <i className='fal fa-map-marker-alt'></i>
                        </div>
                        <div className='footer-address-text'>
                          <span>
                            Community Promoting Foundation, <br />
                            130-132 Wireless Road,
                            <br />
                            Sindhorn Building 2nd Floor, Tower 1, Lumpini,
                            Patumwan, Bangkok Thailand
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='footer-address-icon f-left'>
                          <i className='fal fa-phone'></i>
                        </div>
                        <div className='footer-address-text'>
                          <span>
                            <a href='tel:+6680-694-6395'>+6680-694-6395</a>{' '}
                            <br />
                            {/* <a href="tel:095-064-7658">095-064-7658</a> */}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='footer-address-icon f-left'>
                          <i className='fal fa-envelope-open'></i>
                        </div>
                        <div className='footer-address-text'>
                          <span>
                            <a href='mailto:info@cpfthailand.org'>
                              info@cpfthailand.org
                            </a>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-xl-4 col-lg-6 col-md-7 mb-30'>
                  <div className='footer-wrapper'>
                    <h3 className='footer-title'>News Feeds</h3>
                    {/* <ul className="footer-news">
                      <li>
                        <div className="footer-news-img f-left">
                          <a href="#">
                            <img src="assets/img/footer/01.jpg" alt="" />
                          </a>
                        </div>
                        <div className="footer-news-text">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 20 Jan 2020
                          </span>
                          <h5>
                            <a href="#">
                              We are specialists in both economics and
                              information
                            </a>
                          </h5>
                        </div>
                      </li>
                      <li>
                        <div className="footer-news-img f-left">
                          <a href="#">
                            <img src="assets/img/footer/02.jpg" alt="" />
                          </a>
                        </div>
                        <div className="footer-news-text">
                          <span>
                            <i className="fal fa-calendar-alt"></i> 20 Jan 2020
                          </span>
                          <h5>
                            <a href="#">
                              We are specialists in both economics and
                              information
                            </a>
                          </h5>
                        </div>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom-area red-bg pt-40 pb-40'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-8 col-lg-6 col-md-6'>
                <div className='footer-bottom-wrapper'>
                  {/* <div className='footer-bm-img f-left mr-85'>
                    <img src='assets/img/footer/01.png' alt='' />
                  </div> */}
                  <ul className='footer-bottom-address'>
                    <li>
                      <div className='footer-bottom-icon f-left'>
                        <i className='fal fa-phone'></i>
                      </div>
                      <div className='footer-bottom-text'>
                        <span>Support Phone</span>
                        <h3>
                          <a href='tel:+6680-694-6395'>+6680-694-6395</a>
                        </h3>
                      </div>
                    </li>
                    <li className='f-address'>
                      <div className='footer-bottom-icon f-left'>
                        <i className='fal fa-envelope'></i>
                      </div>
                      <div className='footer-bottom-text'>
                        <span>Support Email</span>
                        <h3>
                          <a href='mailto:info@cpfthailand.org'>
                            info@cpfthailand.org
                          </a>
                        </h3>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-xl-4 col-lg-6 col-md-6'>
                <ul className='footer-bottom-link text-md-right'>
                  <li>
                    <a href='/terms-and-conditions'>Terms & Condition</a>
                  </li>
                  <li>
                    <a href='/privacy-policy'>Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
