import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const RefundPolicy = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Refund Policy</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Refund Policy</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='blog-area pt-20 pb-90'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12 mb-30'>
                <div className='blog-wrapper blog-standard blog-details'>
                  <div className='blog-text'>
                    <h4>
                      <a href='/refund-policy'>Refund Policy</a>
                    </h4>

                    <div className='post-text mb-20'>
                      <div className='about-item mb-20'>
                        <h5>1. Policy Statement: </h5>
                        <p>
                          At Community Promoting Foundation, we value every
                          contribution and are deeply grateful for your support.
                          We understand that there may be circumstances under
                          which a donor needs to request a refund. This policy
                          outlines the process for requesting a refund for
                          donations made to our organization.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>2. Refund Requests: </h5>
                        <p>
                          Donors who wish to request a refund must do so within
                          30 days of making the donation. All refund requests
                          should be submitted in writing to our team via email
                          at{' '}
                          <a href='mailto:info@cpfthailand.org'>
                            info@cpfthailand.org
                          </a>
                          .
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>3. Information Required for Refund: </h5>
                        <p>
                          To process your refund, please include the following
                          details in your request:{' '}
                        </p>
                        <ul>
                          <li>
                            <div className='about-item-text'>
                              <span>Full name and address of the donor.</span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>The amount and date of the donation.</span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                The transaction ID or donation receipt (if
                                available).
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                A clear reason for requesting the refund.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>4. Processing of Refunds: </h5>
                        <p>Upon receiving a refund request, we will:</p>
                        <ul>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                Verify the donation details against our records.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                Process the refund to the original credit card
                                used for the donation.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                Aim to complete the refund process within 14
                                business days from the approval of the request.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>5. Non-Refundable Donations: </h5>
                        <p>
                          Please note that donations earmarked for specific
                          projects or those that have already been utilized for
                          their intended purpose may not be eligible for a
                          refund.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>6. Contact and Queries: </h5>
                        <p>
                          For any questions regarding our refund policy or your
                          donation, please do not hesitate to contact us at{' '}
                          <a href='mailto:info@cpfthailand.org'>
                            info@cpfthailand.org
                          </a>
                          .
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>7. Policy Modifications: </h5>
                        <p>
                          Community Promoting Foundation reserves the right to
                          amend this refund policy at any time.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default RefundPolicy;
