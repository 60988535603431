import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const GetInvolved = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Get Involved</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Get Involved</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='blog-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-6 mb-30'>
                <div className='blog-wrapper blog-standard mb-50'>
                  <div className='blog-text'>
                    <h4>
                      <a href='/get-involved'>
                        Support Our Youth Mental Health Initiative Make Your
                        Contribution Today{' '}
                      </a>
                    </h4>
                    <p>No one has ever become poor by giving</p>
                    <p>
                      <a href='/get-involved/donate' className='c-btn'>
                        Donate Via Credit / Debit Card
                      </a>
                    </p>
                    <p>
                      {/* <b>
                        Please transfer your donation to the following bank
                        account details:
                      </b> */}
                      <b>You can also donate directly into our bank account:</b>
                    </p>
                    <p>
                      <b>Beneficiary: </b> Community Promoting Foundation{' '}
                      <br></br>
                      <b>Bank Name: </b> Bangkok Bank <br></br>
                      <b>Bank Address: </b> 132 Wireless Road. Lumpini,
                      Pathumwan, Bangkok, Thailand 10330 <br></br>
                      <b>Account Number: </b> 046-8-01944-3 <br></br>
                      <b>Swift Code: </b> ВККВТНВК <br></br>
                      <b>Reference: </b> Power of Mental Health for Youth{' '}
                      <br></br>
                    </p>
                    <p>
                      Upon the completion of your donation, we kindly request
                      that you send the transfer receipt, along with your full
                      name and address, to the email provided below.
                      <br></br>
                      <a href='mailto:info@cpfthailand.org'>
                        info@cpfthailand.org
                      </a>
                    </p>
                    <p>
                      This will enable us to issue an official receipt for your
                      valuable contribution. This receipt represents our
                      heartfelt appreciation for your kindness and support.
                    </p>
                    <p>
                      We are deeply thankful for your dedication to improving
                      the mental well-being of young individuals in Thai
                      educational institutions.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6 mb-30'>
                {/* <div className='widget mb-40'>
                  <form className='search-form'>
                    <input type='text' placeholder='Search...' />
                    <button type='submit'>
                      <i className='fas fa-search'></i>
                    </button>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default GetInvolved;
