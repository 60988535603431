import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const ChuthigranDongird = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Our Team</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Our Team</span>
                    </li>
                    <li>
                      <span>Chuthigran Dongird</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='about-area pt-20 pb-90'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 mb-30'>
                <div className='team mb-30'>
                  <div className='team-img'>
                    <img src='assets/img/team/chuthigran-dongird.webp' alt='' />
                  </div>
                  <div className='team-content'>
                    <span>President</span>
                    <h3>
                      <a href='/chuthigran-dongird'>Chuthigran Dongird</a>
                    </h3>
                    {/* <div className='team-social'>
                      <ul>
                        <li>
                          <a href='/chuthigran-dongird'>
                            <i className='fab fa fa-link'></i>{' '}
                            <span>Read more</span>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='col-lg-8 mb-30'>
                <div className='team-details pl-30'>
                  <div className='s-quote'>
                    <span>I’m</span>
                    <p>
                      Chuthigran Dongird, leading a charge at the Community
                      Promoting Foundation against the challenges Thai youth
                      face today, including mental health issues and a worrying
                      rise in obesity. In the last two decades, obesity in
                      children has soared, setting them up for chronic health
                      problems.
                    </p>
                  </div>
                  <h3>My Motivation: </h3>
                  <p>
                    Seeing our youth battle silently with mental and physical
                    health issues, including significant obesity rates, drives
                    me to act. It’s time for change.
                  </p>
                  <h3>Our Mission: </h3>
                  <p>
                    Our goal is to holistically uplift Thai youth, offering them
                    the tools for mental and physical well-being. We conduct
                    educational and health-focused programs to encourage a
                    healthier, supportive lifestyle.
                  </p>
                  <h3>Personal Drive: </h3>
                  <p>
                    As a dedicated mother and advocate, I’m personally invested
                    in nurturing a resilient, healthy next generation.
                  </p>
                  <h3>Join Us: </h3>
                  <p>
                    Support us in creating a healthier future for our youth.
                    Every contribution brings us closer to a brighter tomorrow.
                  </p>
                  <p>
                    <b>With Gratitude,</b>
                    <br></br>
                    Chuthigran Dongird
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default ChuthigranDongird;
