import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const Campaign = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Campaign</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>home</a>
                    </li>
                    <li>
                      <span>Campaign</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='our-services-area s-padding pt-90 pb-100'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-6 mb-15'>
                <div className='our-services-wrapper'>
                  <div className='our-services-img'>
                    <img src='assets/img/about/about-7.webp' alt='' />
                  </div>
                  <div className='our-inner-services'>
                    <div className='number-wrapper nm-01'>
                      <div className='number-info f-left'>
                        <span>01</span>
                      </div>
                      <div className='number-text'>
                        <h3>Project 01</h3>
                        <p>
                          "Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </p>
                        <div style={{ borderTop: '2px solid #f4f8f9' }}>
                          <br></br>
                        </div>
                        <div className='row'>
                          <div className='col-xl-6 col-lg-6 col-md-6'>
                            <a
                              href='/get-involved/donate'
                              className='c-btn'
                              style={{
                                padding: '11px 15px 11px 15px',
                                fontSize: 14,
                              }}
                            >
                              Donate{' '}
                            </a>
                          </div>
                          <div className='col-xl-6 col-lg-6 col-md-6'>
                            <a
                              style={{
                                padding: '10px 14px 10px 14px',
                                fontSize: 14,
                              }}
                              href='contact.html'
                              className='c-btn border-btn'
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 mb-15'>
                <div className='our-services-wrapper'>
                  <div className='our-services-img'>
                    <img src='assets/img/about/about-7.webp' alt='' />
                  </div>
                  <div className='our-inner-services'>
                    <div className='number-wrapper nm-01'>
                      <div className='number-info f-left'>
                        <span>02</span>
                      </div>
                      <div className='number-text'>
                        <h3>Project 02</h3>
                        <p>
                          "Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </p>
                        <div style={{ borderTop: '2px solid #f4f8f9' }}>
                          <br></br>
                        </div>
                        <div className='row'>
                          <div className='col-xl-6 col-lg-6 col-md-6'>
                            <a
                              href='/get-involved/donate'
                              className='c-btn'
                              style={{
                                padding: '11px 15px 11px 15px',
                                fontSize: 14,
                              }}
                            >
                              Donate{' '}
                            </a>
                          </div>
                          <div className='col-xl-6 col-lg-6 col-md-6'>
                            <a
                              style={{
                                padding: '10px 14px 10px 14px',
                                fontSize: 14,
                              }}
                              href='contact.html'
                              className='c-btn border-btn'
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 mb-15'>
                <div className='our-services-wrapper'>
                  <div className='our-services-img'>
                    <img src='assets/img/about/about-7.webp' alt='' />
                  </div>
                  <div className='our-inner-services'>
                    <div className='number-wrapper nm-01'>
                      <div className='number-info f-left'>
                        <span>03</span>
                      </div>
                      <div className='number-text'>
                        <h3>Project 03</h3>
                        <p>
                          "Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </p>
                        <div style={{ borderTop: '2px solid #f4f8f9' }}>
                          <br></br>
                        </div>
                        <div className='row'>
                          <div className='col-xl-6 col-lg-6 col-md-6'>
                            <a
                              href='/get-involved/donate'
                              className='c-btn'
                              style={{
                                padding: '11px 15px 11px 15px',
                                fontSize: 14,
                              }}
                            >
                              Donate{' '}
                            </a>
                          </div>
                          <div className='col-xl-6 col-lg-6 col-md-6'>
                            <a
                              style={{
                                padding: '10px 14px 10px 14px',
                                fontSize: 14,
                              }}
                              href='contact.html'
                              className='c-btn border-btn'
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6 mb-15'>
                <div className='our-services-wrapper'>
                  <div className='our-services-img'>
                    <img src='assets/img/about/about-7.webp' alt='' />
                  </div>
                  <div className='our-inner-services'>
                    <div className='number-wrapper nm-01'>
                      <div className='number-info f-left'>
                        <span>04</span>
                      </div>
                      <div className='number-text'>
                        <h3>Project 04</h3>
                        <p>
                          "Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat.
                        </p>
                        <div style={{ borderTop: '2px solid #f4f8f9' }}>
                          <br></br>
                        </div>
                        <div className='row'>
                          <div className='col-xl-6 col-lg-6 col-md-6'>
                            <a
                              href='/get-involved/donate'
                              className='c-btn'
                              style={{
                                padding: '11px 15px 11px 15px',
                                fontSize: 14,
                              }}
                            >
                              Donate{' '}
                            </a>
                          </div>
                          <div className='col-xl-6 col-lg-6 col-md-6'>
                            <a
                              style={{
                                padding: '10px 14px 10px 14px',
                                fontSize: 14,
                              }}
                              href='contact.html'
                              className='c-btn border-btn'
                            >
                              Learn More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Campaign;
