import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const OurProjectPlans = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Our Plans</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Our Plans</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='blog-area pt-20 pb-90'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12 mb-30'>
                <div className='blog-wrapper blog-standard blog-details'>
                  <div className='blog-text'>
                    <h4>
                      <a href='/our-plans'>Our Project Plans</a>
                    </h4>

                    <div className='post-text mb-20'>
                      <p>
                        We're actively shaping a brighter future. Join us in
                        making a difference.
                      </p>
                      <p>
                        At the Community Promoting Foundation (CPF), we’re on a
                        mission to make Thailand healthier by tackling
                        non-communicable diseases (NCDs) and improving mental
                        health through life skills education.{' '}
                        <b>Here’s how we plan to do it:</b>
                      </p>
                      <h4>Our Simplified Action Plan </h4>
                      <p>
                        <b>Goal: </b> Enhance mental health and fight NCDs
                        across communities.
                      </p>
                      <h4>What We’re Doing </h4>
                      <div className='about-item mb-20'>
                        <ul>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                <b>1. Educational Seminars: </b> Hosting
                                workshops on nutrition, mental well-being, and
                                more for everyone—kids, parents, and teachers.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                <b>2. Expert Talks: </b> Bringing health experts
                                to share tips and insights on living healthier.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                <b>3. Interactive Activities:</b> Creating fun,
                                engaging sessions for learning and sharing in a
                                supportive environment.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                <b>4. Resources for All: </b> Providing
                                materials and support for ongoing learning about
                                health and wellness.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                <b>5. Listening and Adapting: </b> We’re always
                                looking for feedback to make our programs even
                                better.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                <b>6. Teacher Training:</b> Equipping teachers
                                with the skills to teach kids about health and
                                wellness right in the classroom.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <h4>Why It Matters </h4>
                      <div className='about-item mb-20'>
                        <ul>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                <b>Fighting NCDs: </b> Learning life skills
                                helps everyone make smarter health choices,
                                reducing the risk of diseases like diabetes and
                                heart disease.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                <b>Mental Health: </b> These skills also support
                                mental well-being, helping us manage stress and
                                connect with others positively.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <h4>How You Can Help </h4>
                      <p>
                        Interested in helping create a healthier future for
                        Thailand? We’d love to have you join us. Reach out at{' '}
                        <a href='mailto:info@cpfthailand.org'>
                          info@cpfthailand.org
                        </a>{' '}
                        to get involved. Together, we can make a big difference,
                        one life skill at a time. Let’s build a healthier,
                        happier community for everyone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default OurProjectPlans;
