import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const Contact = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + '../assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Contact</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Contact</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='map-area'>
          <div className='map-wrapper'>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.7100054904963!2d100.54254667406401!3d13.735998997661241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29ed8c2a17429%3A0xbb95df4bf8deffac!2sSindhorn%20Building!5e0!3m2!1sen!2sth!4v1710930260810!5m2!1sen!2sth'
              width='600'
              height='450'
              style={{ border: 0 }}
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>
        </div>

        <div className='contact-us-area'>
          <div className='container-fluid p-0'>
            <div className='row no-gutters'>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='conatct-address-wrapper theme-gray  mb-30'>
                  <div className='contact-address-icon f-left'>
                    <i className='fal fa-phone'></i>
                  </div>
                  <div className='contact-address-text fix'>
                    <span>Phone Number</span>
                    <h3>+6680-694-6395</h3>
                  </div>
                  {/* <div className='contact-content mt-30 fix'>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eius mod tempor incididunt ut labore et dolore
                      magna aliqua. Ut enim ad minim veniam, quis nostrud
                      exercitation ullamco.
                    </p>
                  </div> */}
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='conatct-address-wrapper red-bg mpas mb-30'>
                  <div className='contact-address-icon f-left'>
                    <i className='fal fa-map-marker-alt'></i>
                  </div>
                  <div className='contact-address-text fix'>
                    <span>Officce Address</span>
                    <h3>
                      Community Promoting Foundation, 130-132 Wireless Road,
                      Sindhorn Building 2nd Floor, Tower 1, Lumpini, Patumwan,
                      Bangkok Thailand
                    </h3>
                  </div>
                  {/* <div className='contact-content mt-30 fix'>
                    <p className='white-color'>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eius mod tempor incididunt ut labore et dolore
                      magna aliqua. Ut enim ad minim veniam, quis nostrud
                      exercitation ullamco.
                    </p>
                  </div> */}
                </div>
              </div>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='conatct-address-wrapper theme-bg mails mb-30'>
                  <div className='contact-address-icon f-left'>
                    <i className='fal fa-envelope'></i>
                  </div>
                  <div className='contact-address-text fix'>
                    <span>Email Address</span>
                    <h3> info@cpfthailand.org</h3>
                  </div>
                  {/* <div className='contact-content mt-30 fix'>
                    <p className='white-color'>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eius mod tempor incididunt ut labore et dolore
                      magna aliqua. Ut enim ad minim veniam, quis nostrud
                      exercitation ullamco.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Contact;
