import { useState, useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const Home = () => {
  const [items, setItems] = useState([]);

  const [wglang, setWglang] = useState(() => {
    const storedWglang = localStorage.getItem('wglang');
    return storedWglang ? storedWglang : '';
  });
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
    console.log('useEffect.wglang--->', wglang);
    localStorage.setItem('lang', wglang);
    localStorage.setItem('items', JSON.stringify(wglang));
  }, [items]);

  console.log('items--->', items);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <section className='hero-area pos-rel mr-30 ml-30'>
          {/* <div className="slider-icon d-none d-xl-block">
            <a href="#">
              <i className="fal fa-comments"></i>
            </a>
          </div>
          <div className="slider-side d-none d-xl-block">
            <a href="#" className="c-btn">
              {" "}
              <span></span> Free consultancy
            </a>
          </div> */}
          <div className='hero-slider'>
            <div className='slider-active'>
              <div
                className='single-slider slider-height d-flex align-items-center'
                data-background='assets/img/slider/slider-04.webp'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-xl-6 col-lg-7 col-md-9'>
                      <div className='hero-content'>
                        <div className='slider-caption'>
                          {/* <span data-animation="fadeInUp" data-delay=".2s">
                            Empowering Minds for a Brighter Future
                          </span>
                          <span data-animation="fadeInUp" data-delay=".2s">
                            Give Hope, Shape Futures{" "}
                          </span> */}
                          <h2
                            style={{ color: '#009344' }}
                            data-animation='fadeInUp'
                            data-delay='.4s'
                          >
                            {/* HEALTHY STUDENTS, HEALTHY FUTURE. SAVE LIVES TODAY */}
                            HEALTHY MINDS, HEALTHY BODIES: SAVE LIVES TODAY
                          </h2>
                          {/* <p data-animation="fadeInUp" data-delay=".6s">
                            Safeguarding every child's future. Your donation
                            tackles Non-Communicable Diseases (NCDs) across
                            Thailand, reaching the hidden mountain villages.
                            It's not just a donation; it's a lifeline of
                            education and health support to combat mental health
                            issues and depression. Together, let's fight NCDs
                            and pave a brighter, healthier future for our
                            children. Your support fuels this change.
                          </p> */}
                        </div>
                        <div className='slider-btn'>
                          <a
                            data-animation='fadeInUp'
                            data-delay='.8s'
                            href='/get-involved'
                            className='c-btn'
                          >
                            Donate Now
                          </a>
                          {/* <a
                            data-animation="fadeInUp"
                            data-delay=".8s"
                            href="/"
                            className="c-btn border-btn"
                          >
                            Learn More
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              {/* <div className='col-xl-6 col-lg-5'>
                <div className='sycho-tab-img mb-30'>
                  <img src='assets/img/about/exp.png' alt='' />
                </div>
              </div> */}
              <div className='col-xl-12 col-lg-12'>
                <div className='sycho-tab-content pt-20 pr-0 mb-20'>
                  <div className='section-title mb-20'>
                    {/* <span>
                      <i className='far fa-plus'></i> About us
                    </span> */}
                    <h2>Empowering Mental and Physical Well-being</h2>
                    <p></p>
                    <p>
                      <b>Welcome to CPF (Community Promoting Foundation) - </b>{' '}
                      A leading organization committed to empowering mental and
                      physical well-being in Thailand. We are dedicated to
                      addressing critical issues that impact individuals,
                      especially our children, throughout the country.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-5'>
                <div className='sycho-tab-img mb-30'>
                  <img
                    src='assets/img/about/thailands-hidden-mental-health-crisis.webp'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-xl-6 col-lg-7'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='section-title mb-20'>
                    <h3 className='fea-title'>
                      Thailand’s Hidden Mental Health Crisis
                    </h3>
                  </div>
                  <div className='about-item mb-20'>
                    <p>
                      Thailand is facing a serious, unseen mental health
                      challenge:
                    </p>
                    <ul>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            <b>1. High Suicide Rates: </b> Thailand leads the
                            ASEAN region in suicide rates.
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            <b>2. Growing Concerns: </b> Mental health issues
                            increased from 1.3 million people in 2015 to 2.3
                            million in 2021, with a rise in suicides.
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            <b>3. Youth Impact: </b> About 1 in 7 teens and 1 in
                            14 children are affected by mental health disorders.
                            Suicide is the third leading cause of death among
                            Thai teens, with nearly 18% considering it.
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            <b>4. Causes: </b> Violence, bullying, loneliness,
                            and COVID-19 have increased stress, anxiety, and
                            depression among the young.
                          </span>
                        </div>
                      </li>
                    </ul>
                    <p></p>
                    <p>
                      At CPF, we’re committed to tackling these issues to
                      protect our community’s future.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='section-title mb-40'>
                    <h2>The Crisis in Raising Kids Today</h2>
                  </div>
                  <div className='about-item mb-20'>
                    <p>
                      <b>Time to Act</b>
                    </p>
                    <p>
                      A silent crisis is happening right in our homes, affecting
                      our most precious ones: our children. They’re facing a big
                      emotional challenge. In the last 15 years, studies have
                      shown a worrying increase in kids’ mental health problems,
                      reaching levels we can’t ignore anymore.{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-6 col-lg-7 order-2 order-lg-1'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='section-title mb-40'>
                    <h3 className='fea-title'>
                      The numbers are clear and compelling:
                    </h3>
                  </div>
                  <div className='about-item mb-20'>
                    <ul>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            20% of children are grappling with mental health
                            issues.
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>Diagnoses of ADHD have surged by 43%.</span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>Adolescent depression has risen by 37%.</span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            Alarmingly, the suicide rate among 10 to
                            14-year-olds has tripled, marking a 200% increase.
                          </span>
                        </div>
                      </li>
                    </ul>
                    <p></p>
                    <p>
                      These statistics are a wake-up call to the urgent need for
                      action
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-xl-6 col-lg-5 order-1 order-lg-2'>
                <div className='sycho-tab-img mb-30'>
                  <img
                    src='assets/img/about/the-crisis-in-raising-kids-today.webp'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='section-title mb-40'>
                    <h2>What’s Going Wrong with How We’re Raising Kids?</h2>
                  </div>
                  <div className='about-item mb-20'>
                    <p>
                      <b>
                        Today’s kids are getting too many gifts and screen time
                        but are missing out on what they really need for a
                        healthy childhood.
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-6 col-lg-5'>
                <div className='sycho-tab-img mb-30'>
                  <img
                    src='assets/img/about/whats-going-wrong-with-how-we-are-raising-Kids.webp'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-xl-6 col-lg-7'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='section-title mb-40'>
                    <h3 className='fea-title'>They need:</h3>
                  </div>
                  <div className='about-item mb-20'>
                    <ul>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            Parents who are there for them emotionally
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>Clear rules and responsibilities</span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>Healthy food and enough sleep</span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>Time to play outside and be active</span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            Chances to be creative, play with others, and even
                            be bored sometimes
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-6 col-lg-7 order-2 order-lg-1'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='section-title mb-40'>
                    <h3 className='fea-title'>
                      Instead, many kids today have:
                    </h3>
                  </div>
                  <div className='about-item mb-20'>
                    <ul>
                      <li>
                        <div className='about-item-text'>
                          <span>Parents too busy with their phones</span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            Parents who let kids do whatever they want
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            An attitude that they should get everything easily
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>Poor sleep and unhealthy eating habits</span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>Too much sitting and screen time</span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            Non-stop entertainment, leading to no patience or
                            creativity
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='col-xl-6 col-lg-5 order-1 order-lg-2'>
                <div className='sycho-tab-img mb-30'>
                  <img
                    src='assets/img/about/instead-many-kids-today-have.webp'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className='sycho-tab-content pt-20 pr-0 mb-20'>
                  <div className='section-title mb-40'>
                    <h2 className='fea-title'>
                      Our students are facing serious health issues:
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-6 col-lg-5'>
                <div className='sycho-tab-img mb-30'>
                  <img
                    src='assets/img/about/our-students-are-facing-serious-health-issues.webp'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-xl-6 col-lg-7'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='about-item mb-20'>
                    <p>
                      <b>1. Overweight Issues: </b>
                      20% of our students are overweight, with boys being more
                      affected.
                    </p>
                    <p>
                      <b>2. Lack of Activity: </b>
                      More than half (56%) spend 3+ hours sitting daily, showing
                      a high level of inactivity.
                    </p>
                    <p>
                      <b>3. Poor Diet Choices: </b>
                      20% of our students are overweight, with boys being more
                      affected.
                      <ul>
                        <li>
                          <div className='about-item-text'>
                            <span>Over 50% drink sugary sodas regularly.</span>
                          </div>
                        </li>
                        <li>
                          <div className='about-item-text'>
                            <span>Only 33% eat vegetables regularly.</span>
                          </div>
                        </li>
                        <li>
                          <div className='about-item-text'>
                            <span>
                              Only 40% include fruits in their daily diet.
                            </span>
                          </div>
                        </li>
                        <li>
                          <div className='about-item-text'>
                            <span>
                              Fast food is a frequent choice for 55% of
                              students.
                            </span>
                          </div>
                        </li>
                      </ul>
                    </p>
                    <p>
                      <b>4. Smoking Habits: </b>
                      10% of our students smoke, with rates higher in boys and
                      rising among girls.
                    </p>
                    <p>
                      <b>5. Alcohol Consumption: </b>
                      22% of students drink alcohol, with boys drinking more but
                      girls starting to catch up.
                    </p>
                    <p>
                      These trends highlight the need for immediate action to
                      promote healthier lifestyles among our students.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className='sycho-tab-content pt-20 pr-0 mb-20'>
                  <div className='section-title mb-40'>
                    <h2 className='fea-title'>
                      How different health habits impact student’s mental
                      health:
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-6 col-lg-7 order-2 order-lg-1'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='about-item mb-20'>
                    <p>
                      <b>1. Moving Less: </b> Not enough exercise can make
                      students feel more anxious or depressed. Moving more helps
                      lift their mood and reduces stress.
                    </p>
                    <p>
                      <b>2. Eating Habits: </b>
                      Eating too much junk food can make it harder for students
                      to feel good mentally. Healthy foods can help them feel
                      better.
                    </p>
                    <p>
                      <b>3. Sleeping Well: </b> Not getting enough sleep can
                      make students grumpy, less focused, and more likely to
                      feel down. Good sleep is key for a happy mind.
                    </p>
                    <p>
                      <b>4. Screen Time: </b> Spending too much time on screens,
                      especially social media, can make students feel lonely or
                      sad. Less screen time means better mental health.
                    </p>
                    <p>
                      <b>5. Substance Use: </b>
                      Trying drugs or alcohol can really hurt a student’s mental
                      health, leading to more sadness or anxiety.
                    </p>
                    <p>
                      <b>6. Being Kind Online: </b>
                      When students face bullying, especially online, it can
                      really hurt their self-esteem and happiness. A kinder
                      environment helps everyone feel better.
                    </p>
                    <p>
                      By understanding these points, we can work together to
                      make healthier choices that support our mental well-being.
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-xl-6 col-lg-5 order-1 order-lg-2'>
                <div className='sycho-tab-img mb-30'>
                  <img
                    src='assets/img/about/how-different-health-habits-impact-students-mental-health.webp'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className='sycho-tab-content pt-20 pr-0 mb-20'>
                  <div className='section-title mb-20'>
                    <h2 className='fea-title'>
                      Join Our Mission: Building a Healthier Future for Our Kids
                    </h2>
                    <p></p>
                    <p>
                      Let's focus on the basics to make our kids happier and
                      healthier. Changes can happen quickly!
                    </p>
                    <p>
                      We're all in to improve our students' lives. Through
                      specific actions, learning, and targeted interventions, we
                      aim to build a healthier school community.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-50'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className='sycho-tab-content pt-20 pr-0 mb-20'>
                  <div className='section-title mb-20'>
                    <h2 className='fea-title'>Be Part of the Change:</h2>
                    <p></p>
                    <p>
                      Join us in making a meaningful difference in our kids'
                      well-being. Together, we can create a positive impact on
                      their health and our school's environment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='about-area theme-red-soft'>
          <div className='container container-custom'>
            <div className='white-bg about-box'>
              <div className='row'>
                <div className='col-xl-5 col-lg-5'>
                  <div className='sycho-tab-img mb-30'>
                    <img src='assets/img/about/about-7.webp' alt='' />
                  </div>
                </div>
                <div className='col-xl-7 col-lg-7'>
                  <div className='sycho-tab-content mb-30'>
                    <div className='section-title mb-40'>
                      <span>
                        <i className='far fa-plus'></i> CPF (Community Promoting
                        Foundation)
                      </span>
                      <h2>ABOUT US</h2>
                      <br></br>
                      <p style={{ fontSize: 20 }}>
                        CPF (Community Promoting Foundation) brings together
                        teachers, parents, inspiring speakers, and wellness
                        experts to help our youth with their mental health.
                        We've created a program that mixes learning by doing,
                        community involvement, and teaching important life
                        values, making it easier for young people to deal with
                        emotional challenges. At the same time, we're tackling
                        Thailand's growing problem with Non-Communicable
                        Diseases (NCDs) by adding health education to our
                        program, aiming to build a stronger and healthier
                        younger generation.
                      </p>
                      <div className='mb-40'></div>
                      <a href='/our-plans' className='c-btn'>
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </main>
      <Footer />
    </>
  );
};
export default Home;
