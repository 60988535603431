import {
  FormControl,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React from 'react';

const Header = () => {
  const [language, setLanguage] = React.useState('thai');

  const handleLanguageChange = (event: SelectChangeEvent) => {
    setLanguage(event.target.value as string);
  };
  console.log('language--->', language);
  return (
    <>
      <header>
        <div className='header-top-area red-bg'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-6 col-lg-7 col-md-6'>
                <div className='header-wrapper'>
                  <div className='header-link'>
                    <ul>
                      <li>
                        <a href='/'>
                          Welcome to Community Promoting Foundation
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-xl-6 col-lg-5 col-md-6'>
                <div className='header-top-right'>
                  <div className='header-info text-md-right'>
                    <span>
                      <i className='far fa-envelope-open'></i>{' '}
                      <a href='mailto:info@cpfthailand.org'>
                        info@cpfthailand.org
                      </a>
                    </span>
                    <span>
                      <i className='far fa-phone'></i>
                      <a href='tel:+6680-694-6395'>+6680-694-6395</a>
                    </span>
                    {/* <span>
                      <FormControl>
                        <InputLabel id='demo-simple-select-label'>
                          Language
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          value={language}
                          label='Language'
                          onChange={handleLanguageChange}
                        >
                          <MenuItem value={'thai'}>ภาษาไทย</MenuItem>
                          <MenuItem value={'eng'}>English</MenuItem>
                        </Select>
                      </FormControl>
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='sticky-header' className='main-menu-area menu-01 pl-45 pr-45'>
          <div className='container-fluid'>
            <div className='row align-items-center'>
              <div className='col-xl-3 col-lg-3'>
                <div className='logo'>
                  <a href='/'>
                    <img
                      src='../assets/img/logo/community-logo-new.png'
                      alt=''
                    />
                  </a>
                </div>
              </div>
              <div className='col-xl-6 col-lg-6'>
                <div className='main-menu'>
                  <nav id='mobile-menu'>
                    <ul>
                      <li>
                        <a href='/'>Home </a>
                      </li>
                      <li>
                        <a href='/'>
                          About<i className='far fa-plus'></i>
                        </a>
                        <ul className='sub-menu text-left'>
                          <li>
                            <a href='/vision-mission'>Vision & Mission</a>
                          </li>
                          <li>
                            <a href='/team'>Our Team</a>
                          </li>
                          <li>
                            <a href='/our-journey'>Our Journey</a>
                          </li>
                          <li>
                            <a href='/objective'>Objective</a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <a href='/our-project-plans'>Our Plans </a>
                      </li>
                      <li>
                        <a href='/child-health-matters'>
                          Child Health Matters{' '}
                        </a>
                      </li>
                      <li>
                        <a href='/fundraising-campaign'>Campaign </a>
                      </li>
                      <li>
                        <a href='/contact'>Contact</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className='col-xl-3 col-lg-3'>
                <div className='header-right d-none d-lg-block'>
                  <div className='menu-bar info-bar f-right d-none d-md-none d-xl-block'>
                    <a href='#'>
                      <img src='../assets/img/icon/bar.png' alt='' />
                    </a>
                  </div>
                  <div className='header-button f-right'>
                    <a href='/get-involved' className='c-btn'>
                      Donate Now
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='mobile-menu'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='extra-info'>
          <div className='close-icon'>
            <button>
              <i className='far fa-window-close'></i>
            </button>
          </div>
          <div className='logo-side mb-30'>
            <a href='/'>
              <img src='../assets/img/logo/logo-side-new.png' alt='' />
            </a>
          </div>
          <div className='side-info mb-30'>
            <div className='contact-list mb-30'>
              <h4>Office Address</h4>
              <p>
                Community Promoting Foundation <br></br> 130-132 Wireless Road,
                <br></br> Sindhorn Building 2nd Floor, Tower 1, Lumpini,
                Patumwan, Bangkok Thailand
              </p>
            </div>
            <div className='contact-list mb-30'>
              <h4>Phone Number</h4>
              <p>
                <a href='tel:+6680-694-6395'>+6680-694-6395</a>
              </p>
              {/* <p>
                <a href='tel:095-064-7658'>095-064-7658</a>
              </p> */}
            </div>
            <div className='contact-list mb-30'>
              <h4>Email Address</h4>
              <p>
                <a href='mailto:info@cpfthailand.org'>info@cpfthailand.org</a>
              </p>
            </div>
          </div>
          {/* <div className="instagram">
            <a href="#">
              <img src="assets/img/portfolio/p1.jpg" alt="" />
            </a>
            <a href="#">
              <img src="assets/img/portfolio/p2.jpg" alt="" />
            </a>
            <a href="#">
              <img src="assets/img/portfolio/p3.jpg" alt="" />
            </a>
            <a href="#">
              <img src="assets/img/portfolio/p4.jpg" alt="" />
            </a>
            <a href="#">
              <img src="assets/img/portfolio/p5.jpg" alt="" />
            </a>
            <a href="#">
              <img src="assets/img/portfolio/p6.jpg" alt="" />
            </a>
          </div> */}
          <div className='social-icon-right mt-20'>
            <a href='#'>
              <i className='fab fa-facebook-f'></i>
            </a>
            <a href='#'>
              <i className='fab fa-twitter'></i>
            </a>
            <a href='#'>
              <i className='fab fa-google-plus-g'></i>
            </a>
            <a href='#'>
              <i className='fab fa-instagram'></i>
            </a>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
