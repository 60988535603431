export const ROUTES = {
  HOME: '/',
  OURPLANS: '/our-plans',
  OURPROJECTPLANS: '/our-project-plans',
  OURJOURNEY: '/our-journey',
  VISIONMISSION: '/vision-mission',
  OBJECTIVE: '/objective',
  DONATE: '/get-involved/donate',
  GETINVOLVED: '/get-involved',
  TEAM: '/team',
  CHUTHIGRANDONGIRD: '/chuthigran-dongird',
  REFUNDPOLICY: '/refund-policy',
  TERMSANDCONDITIONS: '/terms-and-conditions',
  PRIVACYPOLICY: '/privacy-policy',
  CAMPAIGN: '/campaign',
  CAMPAIGNSUPPORTINGTHAIYOUTHSMENTALHEALTH:
    '/campaign/supporting-thai-youths-mental-health',
  CAMPAIGNHELPTEACHERSHELPOURKIDS: '/campaign/help-teachers-help-our-kids',
  FUNDRAISINGCAMPAIGN: '/fundraising-campaign',
  CHILDHEALTHMATTERS: '/child-health-matters',
  CONTACT: '/contact',
  THANKYOU: '/thank-you',
};
