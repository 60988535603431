import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const TermsAndConditions = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Terms And Conditions</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Terms And Conditions</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='blog-area pt-20 pb-90'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12 mb-30'>
                <div className='blog-wrapper blog-standard blog-details'>
                  <div className='blog-text'>
                    <h4>
                      <a href='/terms-and-conditions'>Terms And Conditions</a>
                    </h4>

                    <div className='post-text mb-20'>
                      <div className='about-item mb-20'>
                        <h5>1. Introduction: </h5>
                        <p>
                          Welcome to the Community Promoting Foundation. These
                          Donation Terms and Conditions apply to all donations
                          made to our foundation.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>2. Donation Limit: </h5>
                        <p>
                          Maximum donation: 100,000 Thai Baht (THB) per
                          individual.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>3. Use of Funds: </h5>
                        <p>
                          Donations will be utilized for community promotion
                          activities at the discretion of the Foundation.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>4. Refund Policy: </h5>
                        <p>All donations are final and non-refundable.</p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>5. Acknowledgment: </h5>
                        <p>
                          Donors will receive a formal acknowledgment for their
                          contribution.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>6. Donor Privacy: </h5>
                        <p>
                          Donor information will be treated confidentially as
                          per our Privacy Policy.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>7. Legal Compliance: </h5>
                        <p>
                          All donations are subject to the prevailing laws and
                          regulations.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>8. Amendments: </h5>
                        <p>
                          The Foundation reserves the right to amend these
                          terms.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default TermsAndConditions;
