import { useEffect } from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";

const VisionMission = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log("Home");
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement("script");
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName("body")[0].appendChild(tag);
    };
    loadScript("../assets/js/vendor/modernizr-3.5.0.min.js");
    loadScript("../assets/js/vendor/jquery-1.12.4.min.js");
    loadScript("../assets/js/popper.min.js");
    loadScript("../assets/js/bootstrap.min.js");
    loadScript("../assets/js/owl.carousel.min.js");
    loadScript("../assets/js/isotope.pkgd.min.js");
    loadScript("../assets/js/slick.min.js");
    loadScript("../assets/js/jquery.meanmenu.min.js");
    loadScript("../assets/js/ajax-form.js");
    loadScript("../assets/js/wow.min.js");
    loadScript("../assets/js/jquery.knob.js");
    loadScript("../assets/js/jquery.appear.js");
    loadScript("../assets/js/waypoints.min.js");
    loadScript("../assets/js/jquery.counterup.min.js");
    loadScript("../assets/js/jquery.scrollUp.min.js");
    loadScript("../assets/js/imagesloaded.pkgd.min.js");
    loadScript("../assets/js/jquery.magnific-popup.min.js");
    loadScript("../assets/js/plugins.js");
    loadScript("../assets/js/main.js");

    return () => {
      document.getElementsByTagName("body")[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className="breadcrumb-area pt-150 pb-150"
          data-overlay="7"
          style={{
            backgroundImage: "url(" + "assets/img/bg/page-title.webp" + ")",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="breadcrumb-text text-center z-index">
                  <h1>Vision & Mission</h1>
                  <ul className="breadcrumb-menu">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <span>Vision & Mission</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-area pt-20 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 mb-30">
                <div className="blog-wrapper blog-standard blog-details">
                  <div className="blog-text">
                    <h4>
                      <a href="/vision-mission">Mission:</a>
                    </h4>

                    <div className="post-text mb-20">
                      <p>
                        At the Community Promoting Foundation (CPF), we're all
                        about supporting our youth to overcome emotional and
                        mental challenges. We go beyond just talking about the
                        issues; we're all about finding real solutions to
                        improve young people’s well-being. By bringing
                        communities and schools together, we're laying a strong
                        mental and emotional foundation for our youth, aiming
                        for a happier, healthier society.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 mb-30">
                <div className="blog-wrapper blog-standard blog-details">
                  <div className="blog-text">
                    <h4>
                      <a href="/vision-mission">Impactful Vision:</a>
                    </h4>
                    <div className="post-text mb-20">
                      <p>
                        We dream of a future where our youth's mental well-being
                        is a priority. A future where every child is mentally
                        strong to face life's challenges, schools are pillars of
                        emotional support, and homes are safe spaces of
                        understanding and acceptance. We aim to change the
                        narrative of youth mental health from despair to hope,
                        resilience, and collective growth.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default VisionMission;
