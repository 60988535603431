import React from 'react';
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import { ROUTES } from './Constants/index';
import './App.css';
import Home from './Views/Home/Home';
import OurPlans from './Views/Home/OurPlans';
import OurJourney from './Views/Home/OurJourney';
import VisionMission from './Views/Home/VisionMission';
import Objective from './Views/Home/Objective';
import Donate from './Views/GetInvolved/Donate';
import GetInvolved from './Views/GetInvolved/GetInvolved';
import Team from './Views/Team/Team';
import ChuthigranDongird from './Views/Team/ChuthigranDongird';
import RefundPolicy from './Views/GetInvolved/RefundPolicy';
import TermsAndConditions from './Views/GetInvolved/TermsAndConditions';
import PrivacyPolicy from './Views/GetInvolved/PrivacyPolicy';
import Campaign from './Views/Campaign/Campaign';
import ChildHealthMatters from './Views/GetInvolved/ChildHealthMatters';
import OurProjectPlans from './Views/Home/OurProjectPlans';
import FundraisingCampaign from './Views/Campaign/FundraisingCampaign';
import SupportingThaiYouthsMentalHealth from './Views/Campaign/SupportingThaiYouthsMentalHealth';
import HelpTeachersHelpOurKids from './Views/Campaign/HelpTeachersHelpOurKids';
import Contact from './Views/Home/Contact';
import ThankYou from './Views/GetInvolved/ThankYou';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* <Switch> */}
          {/* {" "} */}
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.OURPLANS} element={<OurPlans />} />
          <Route path={ROUTES.OURPROJECTPLANS} element={<OurProjectPlans />} />
          <Route path={ROUTES.OURJOURNEY} element={<OurJourney />} />
          <Route path={ROUTES.VISIONMISSION} element={<VisionMission />} />
          <Route path={ROUTES.OBJECTIVE} element={<Objective />} />
          <Route path={ROUTES.DONATE} element={<Donate />} />
          <Route path={ROUTES.GETINVOLVED} element={<GetInvolved />} />
          <Route path={ROUTES.TEAM} element={<Team />} />
          <Route
            path={ROUTES.CHUTHIGRANDONGIRD}
            element={<ChuthigranDongird />}
          />
          <Route path={ROUTES.REFUNDPOLICY} element={<RefundPolicy />} />
          <Route
            path={ROUTES.TERMSANDCONDITIONS}
            element={<TermsAndConditions />}
          />
          <Route path={ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />} />
          <Route path={ROUTES.CAMPAIGN} element={<Campaign />} />
          <Route
            path={ROUTES.FUNDRAISINGCAMPAIGN}
            element={<FundraisingCampaign />}
          />
          <Route
            path={ROUTES.CAMPAIGNSUPPORTINGTHAIYOUTHSMENTALHEALTH}
            element={<SupportingThaiYouthsMentalHealth />}
          />
          <Route
            path={ROUTES.CAMPAIGNHELPTEACHERSHELPOURKIDS}
            element={<HelpTeachersHelpOurKids />}
          />
          <Route
            path={ROUTES.CHILDHEALTHMATTERS}
            element={<ChildHealthMatters />}
          />
          <Route path={ROUTES.CONTACT} element={<Contact />} />
          <Route path={ROUTES.THANKYOU} element={<ThankYou />} />

          {/* </Switch> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
