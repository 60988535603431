import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import { Alert } from '@mui/material';
const ThankYou = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Thank You</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Thank You</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='blog-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12 mb-30'>
                <div className='blog-wrapper blog-standard blog-details'>
                  <div className='blog-text'>
                    {/* <h4>
                      <a href='/privacy-policy'>Privacy Policy</a>
                    </h4> */}

                    <div className='post-text mb-20'>
                      <div className='about-item mb-20'>
                        <Alert style={{ marginTop: 10 }} severity='success'>
                          <b>
                            {' '}
                            Thank you so much for your generous donation,
                            detailed receipt has been emailed to you . you will
                            receive email shortly
                          </b>
                        </Alert>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default ThankYou;
