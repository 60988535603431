import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const OurJourney = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>our Journey</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Our Journey</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='blog-area pt-20 pb-90'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12 mb-30'>
                <div className='blog-wrapper blog-standard blog-details'>
                  <div className='blog-text'>
                    <h4>
                      <a href='/our-journey'>
                        Our Mission: A Healthier Future for Kids
                      </a>
                    </h4>

                    <div className='post-text mb-20'>
                      <h3>The Start: </h3>
                      <p>
                        In 2019, driven by a deep concern for children’s health,
                        our founder noticed worrying health trends among kids,
                        including diseases usually found in adults and rising
                        emotional distress. This sparked our mission to protect
                        the well-being of the young.
                      </p>
                      <h3>The Problem: </h3>
                      <p>
                        Children are increasingly affected by diseases usually
                        seen in adults, driven by unhealthy habits and online
                        misinformation. Emotional distress and thoughts of
                        self-harm are on the rise.
                      </p>
                      <h3>The Solution: </h3>
                      <p>
                        Believing in the power of education, we focus on
                        teaching essential life skills, particularly in health,
                        through schools. We know that healthy kids do better in
                        school and sports, and we’re committed to making a real
                        difference.
                      </p>
                      <h3>Our Impact: </h3>
                      <p>
                        We’ve been dedicated to helping Thailand’s children,
                        promoting positive habits through education. Schools are
                        key in this effort, providing a trusted environment for
                        learning life’s crucial lessons.
                      </p>
                      <h3>Join Us: </h3>
                      <p></p>
                      <p>
                        Your support can change lives, adding to the positive
                        change we’re already seeing. Interested? Contact us at{' '}
                        <a href='mailto:info@cpfthailand.org'>
                          info@cpfthailand.org
                        </a>
                        .
                      </p>
                      <p>
                        Let’s build a healthier future for our children
                        together.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default OurJourney;
