import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const HelpTeachersHelpOurKids = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + '../assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Help Teachers, Help Our Kids</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Campaign</span>
                    </li>
                    <li>
                      <span>Help Teachers, Help Our Kids</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='case-details pt-50 pb-90'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='case-details-thumb'>
                  <img
                    src='../assets/img/campaign/help-teachers-help-our-kids.webp'
                    alt=''
                  />
                </div>
                <div className='cd-info theme-bg'>
                  <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                      <div className='cd-list  mb-15'>
                        {/* <span>Client</span> */}
                        <h3>Help Teachers, Help Our Kids</h3>
                      </div>
                    </div>

                    {/* <div className='col-lg-3 col-md-6'>
                      <div className='cd-list text-lg-right mb-15'>
                        <a href='#'>
                          <i className='fab fa-facebook-f'></i>
                        </a>
                        <a href='#'>
                          <i className='fab fa-twitter'></i>
                        </a>
                        <a href='#'>
                          <i className='fal fa-envelope'></i>
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className='case-details mt-20'>
                  <p>
                    <b>Empower Teachers: </b> They’re not just educators;
                    they’re life coaches who influence our kids’ growth,
                    learning, resilience, and mental health. At the Community
                    Promoting Foundation (CPF), we aim to equip them with the
                    tools to be exceptional role models.
                  </p>
                  <h4>How Your Support Helps </h4>
                  <div className='about-item mb-20'>
                    <ul>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            <b>Teacher Training: </b> We give teachers special
                            training so they can teach important life and health
                            lessons better.
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            <b>Classroom Tools: </b> We make sure classrooms
                            have the latest tools and info for teaching health
                            topics.
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            <b>Teacher Health: </b> We look after our teachers’
                            health and happiness, so they feel good and teach
                            well.
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <h4>Healthier Lifestyles Start in the Classroom </h4>
                  <div className='about-item mb-20'>
                    <ul>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            <b>1. Healthy Choices Start with Learning: </b>{' '}
                            Teachers help kids learn how to make good choices
                            for their bodies and minds. This can help stop
                            sicknesses like diabetes and heart problems.
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            <b>2. Teachers + Parents = Stronger Community: </b>{' '}
                            Teachers don’t work alone. They team up with parents
                            to spread the word on staying healthy. When everyone
                            knows how to be healthy, the whole community gets
                            better.
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className='about-item-text'>
                          <span>
                            <b>
                              3. Being Healthy is Good for Your Body and Mind:{' '}
                            </b>{' '}
                            Making changes to eat better and move more doesn’t
                            just help your body; it also makes your mind
                            happier. Plus, it can keep sickness away and make
                            families happier together.
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <p>
                    So, teachers are super important because they start teaching
                    kids how to be healthy early on. This helps everyone—not
                    just the kids, but their families and the whole community—to
                    be healthier and happier.
                  </p>
                  <h4>Teachers as Health Champions </h4>
                  <p>
                    By focusing on healthy living, teachers play a vital role in
                    preventing diseases and supporting mental health,
                    potentially aiding in their treatment. This education effort
                    can positively impact lifestyle diseases and mental health.
                  </p>
                  <h4>Join Us </h4>
                  <p>
                    <b>Every Donation Counts: </b> Your support enables CPF to
                    empower teachers and foster a healthier, brighter future for
                    children, encompassing both physical and mental health.
                  </p>
                  <p>
                    <b>Act Now for a Better Tomorrow: </b> Your Help us promote
                    a legacy of health and joy, tackling lifestyle diseases and
                    enhancing mental well-being for our children.
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='row'>
                  <div className='col-xl-6 col-lg-6 col-md-6'>
                    <a
                      href='/get-involved'
                      className='c-btn'
                      style={{
                        padding: '22px 30px 22px 30px',
                        fontSize: 14,
                        marginBottom: 10,
                      }}
                    >
                      Donate Now{' '}
                    </a>
                  </div>
                  {/* <div className='col-xl-6 col-lg-6 col-md-6'>
                    <a
                      style={{
                        padding: '10px 14px 10px 14px',
                        fontSize: 14,
                        marginBottom: 10,
                      }}
                      href='/campaign/supporting-thai-youths-mental-health'
                      className='c-btn border-btn'
                    >
                      Learn More
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default HelpTeachersHelpOurKids;
