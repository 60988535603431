import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const FundraisingCampaign = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Fundraising Campaign </h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>home</a>
                    </li>
                    <li>
                      <span>Campaign</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='tab-area pt-50 pb-90 theme-red-soft'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-xl-8 col-lg-8'>
                <div className='section-title text-center mb-65'>
                  {/* <span>
                    <i className='far fa-plus'></i> features
                    <i className='far fa-plus pl-10'></i>
                  </span> */}
                  <h3>Promoting Positive Mental Health in Thai Youth </h3>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='sycho-cf-menu text-center mb-20'>
                  <ul className='nav mt-0' id='myTab' role='tablist'>
                    <li className='nav-item' role='presentation'>
                      <a
                        className='nav-link active'
                        id='home-tab'
                        data-toggle='tab'
                        href='#home'
                        role='tab'
                        aria-controls='home'
                        aria-selected='true'
                      >
                        <i className='fal fa-star'></i> All Campaigns
                      </a>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <a
                        className='nav-link'
                        id='profile-tab'
                        data-toggle='tab'
                        href='#profile'
                        role='tab'
                        aria-controls='profile'
                        aria-selected='false'
                      >
                        <i className='fal fa-comments'></i> Fundraising
                        Campaigns
                      </a>
                    </li>
                    <li className='nav-item' role='presentation'>
                      <a
                        className='nav-link'
                        id='contact-tab'
                        data-toggle='tab'
                        href='#contact'
                        role='tab'
                        aria-controls='contact'
                        aria-selected='false'
                      >
                        <i className='fal fa-award'></i> Completed Campaigns
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='sycho-cf-wrapper'>
                  <div className='tab-content' id='myTabContent'>
                    <div
                      className='tab-pane fade show active'
                      id='home'
                      role='tabpanel'
                      aria-labelledby='home-tab'
                    >
                      <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-6 mb-15'>
                          <div
                            className='our-services-wrapper'
                            style={{ padding: 5 }}
                          >
                            <div className='our-services-img'>
                              <img
                                src='assets/img/about/thumbnail/supporting-thai-youths-mental-health.webp'
                                alt='rting Thai Youth’s Mental Health'
                              />
                            </div>
                            <div className='our-inner-services'>
                              <div className='number-wrapper nm-01'>
                                <div className='number-info f-left'>
                                  <span>01</span>
                                </div>
                                <div className='number-text'>
                                  <h3>Supporting Thai Youth’s Mental Health</h3>
                                  <p>
                                    Our mission is clear: to offer the support,
                                    education, and tools necessary for
                                    Thailand’s young people to build resilience,
                                    cope with stress, and face life’s challenges
                                    with confidence.
                                  </p>
                                  <div
                                    style={{
                                      borderTop: '2px solid #f4f8f9',
                                    }}
                                  >
                                    <br></br>
                                  </div>
                                  <div className='row'>
                                    <div className='col-6'>
                                      <a
                                        href='/get-involved'
                                        className='c-btn'
                                        style={{
                                          padding: '11px 15px 11px 15px',
                                          fontSize: 14,
                                          marginBottom: 10,
                                        }}
                                      >
                                        Donate{' '}
                                      </a>
                                    </div>
                                    <div className='col-6'>
                                      <a
                                        style={{
                                          padding: '10px 14px 10px 14px',
                                          fontSize: 14,
                                          marginBottom: 10,
                                        }}
                                        href='/campaign/supporting-thai-youths-mental-health'
                                        className='c-btn border-btn'
                                      >
                                        Learn More
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-xl-4 col-lg-4 col-md-6 mb-15'>
                          <div
                            className='our-services-wrapper'
                            style={{ padding: 5 }}
                          >
                            <div className='our-services-img'>
                              <img
                                src='assets/img/campaign/thumbnail/help-teachers-help-our-kids.webp'
                                alt='rting Thai Youth’s Mental Health'
                              />
                            </div>
                            <div className='our-inner-services'>
                              <div className='number-wrapper nm-01'>
                                <div className='number-info f-left'>
                                  <span>02</span>
                                </div>
                                <div className='number-text'>
                                  <h3>Help Teachers, Help Our Kids</h3>
                                  <p>
                                    Empower Teachers: They’re not just
                                    educators; they’re life coaches who
                                    influence our kids’ growth, learning,
                                    resilience, and mental health. At the
                                    Community Promoting Foundation (CPF), we aim
                                    to equip them with the tools to be
                                    exceptional role models.
                                  </p>
                                  <div
                                    style={{
                                      borderTop: '2px solid #f4f8f9',
                                    }}
                                  >
                                    <br></br>
                                  </div>
                                  <div className='row'>
                                    <div className='col-6'>
                                      <a
                                        href='/get-involved'
                                        className='c-btn'
                                        style={{
                                          padding: '11px 15px 11px 15px',
                                          fontSize: 14,
                                          marginBottom: 10,
                                        }}
                                      >
                                        Donate{' '}
                                      </a>
                                    </div>
                                    <div className='col-6'>
                                      <a
                                        style={{
                                          padding: '10px 14px 10px 14px',
                                          fontSize: 14,
                                          marginBottom: 10,
                                        }}
                                        href='/campaign/help-teachers-help-our-kids'
                                        className='c-btn border-btn'
                                      >
                                        Learn More
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='profile'
                      role='tabpanel'
                      aria-labelledby='profile-tab'
                    >
                      <div className='row'>
                        <div className='col-xl-4 col-lg-4 col-md-6 mb-15'>
                          <div
                            className='our-services-wrapper'
                            style={{ padding: 5 }}
                          >
                            <div className='our-services-img'>
                              <img
                                src='assets/img/about/thumbnail/supporting-thai-youths-mental-health.webp'
                                alt='rting Thai Youth’s Mental Health'
                              />
                            </div>
                            <div className='our-inner-services'>
                              <div className='number-wrapper nm-01'>
                                <div className='number-info f-left'>
                                  <span>01</span>
                                </div>
                                <div className='number-text'>
                                  <h3>Supporting Thai Youth’s Mental Health</h3>
                                  <p>
                                    Our mission is clear: to offer the support,
                                    education, and tools necessary for
                                    Thailand’s young people to build resilience,
                                    cope with stress, and face life’s challenges
                                    with confidence.
                                  </p>
                                  <div
                                    style={{
                                      borderTop: '2px solid #f4f8f9',
                                    }}
                                  >
                                    <br></br>
                                  </div>
                                  <div className='row'>
                                    <div className='col-6'>
                                      <a
                                        href='/get-involved'
                                        className='c-btn'
                                        style={{
                                          padding: '11px 15px 11px 15px',
                                          fontSize: 14,
                                          marginBottom: 10,
                                        }}
                                      >
                                        Donate{' '}
                                      </a>
                                    </div>
                                    <div className='col-6'>
                                      <a
                                        style={{
                                          padding: '10px 14px 10px 14px',
                                          fontSize: 14,
                                          marginBottom: 10,
                                        }}
                                        href='/campaign/supporting-thai-youths-mental-health'
                                        className='c-btn border-btn'
                                      >
                                        Learn More
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-4 col-lg-4 col-md-6 mb-15'>
                          <div
                            className='our-services-wrapper'
                            style={{ padding: 5 }}
                          >
                            <div className='our-services-img'>
                              <img
                                src='../assets/img/about/thumbnail/help-teachers-help-our-kids.webp'
                                alt='rting Thai Youth’s Mental Health'
                              />
                            </div>
                            <div className='our-inner-services'>
                              <div className='number-wrapper nm-01'>
                                <div className='number-info f-left'>
                                  <span>02</span>
                                </div>
                                <div className='number-text'>
                                  <h3>Help Teachers, Help Our Kids</h3>
                                  <p>
                                    Empower Teachers: They’re not just
                                    educators; they’re life coaches who
                                    influence our kids’ growth, learning,
                                    resilience, and mental health. At the
                                    Community Promoting Foundation (CPF), we aim
                                    to equip them with the tools to be
                                    exceptional role models.
                                  </p>
                                  <div
                                    style={{
                                      borderTop: '2px solid #f4f8f9',
                                    }}
                                  >
                                    <br></br>
                                  </div>
                                  <div className='row'>
                                    <div className='col-6'>
                                      <a
                                        href='/get-involved'
                                        className='c-btn'
                                        style={{
                                          padding: '11px 15px 11px 15px',
                                          fontSize: 14,
                                          marginBottom: 10,
                                        }}
                                      >
                                        Donate{' '}
                                      </a>
                                    </div>
                                    <div className='col-6'>
                                      <a
                                        style={{
                                          padding: '10px 14px 10px 14px',
                                          fontSize: 14,
                                          marginBottom: 10,
                                        }}
                                        href='/campaign/help-teachers-help-our-kids'
                                        className='c-btn border-btn'
                                      >
                                        Learn More
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className='tab-pane fade'
                      id='contact'
                      role='tabpanel'
                      aria-labelledby='contact-tab'
                    >
                      <div className='row'>
                        <div className='col-xl-12 col-lg-12'>
                          <p>No Completed Campains Found</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default FundraisingCampaign;
