import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const ChildHealthMatters = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Child Health Matters</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Child Health Matters</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='blog-area pt-20 pb-90'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12 mb-30'>
                <div className='blog-wrapper blog-standard blog-details'>
                  <div className='blog-text'>
                    {/* <h4>
                      <a href='/our-journey'>
                        Our Mission: A Healthier Future for Kids
                      </a>
                    </h4> */}
                    <div className='post-text mb-20'>
                      <h3>
                        Tackling Non-Communicable Diseases (NCDs) in Thailand{' '}
                      </h3>
                      <p>
                        CPF is addressing the urgent issue of non-communicable
                        diseases (NCDs) in Thailand, where sedentary lifestyles,
                        poor diets, tobacco, and alcohol are major contributors.
                        NCDs, including cancers, heart diseases, diabetes, and
                        chronic respiratory conditions, are responsible for over
                        1,000 deaths daily, totaling around 400,000 annually.
                        Our comprehensive strategy promotes healthier living,
                        preventative healthcare, and improved medical access to
                        combat this crisis.
                      </p>
                      <h3>
                        How Chronic Illnesses Affect Kids’ Mental Health in
                        Thailand:{' '}
                      </h3>
                      <p>
                        <b>
                          Chronic illnesses can be tough on kids and teens,
                          affecting their mental health in several ways:
                        </b>
                      </p>
                      <div className='about-item mb-20'>
                        <ul>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                1. Stress: Kids with chronic conditions often
                                worry a lot about their health and future,
                                leading to stress and anxiety.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                2. Feeling Left Out: Being sick can make kids
                                feel alone or different, especially if they
                                can’t join in activities or face stigma from
                                others.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                3. School Struggles: Missing a lot of school for
                                health reasons can make keeping up with studies
                                and friends hard, adding extra stress.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                4. Family Worries: Kids might feel like a burden
                                to their families, which can hurt their
                                self-esteem and happiness.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                5. Missing Fun: Not being able to do fun
                                activities can make kids feel sad and left out.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                6. Sleep Problems: Health issues can disrupt
                                sleep, making mental health challenges worse.
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className='about-item-text'>
                              <span>
                                7. Identity Questions: Teens might worry about
                                their future and who they are because of their
                                illness, affecting their mood and outlook.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <p>
                        Supporting these kids means looking after their mental
                        health, making sure they’re included, and tackling any
                        stigma they face.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default ChildHealthMatters;
