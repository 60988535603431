import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const PrivacyPolicy = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Privacy Policy</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Privacy Policy</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='blog-area pt-20 pb-90'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12 mb-30'>
                <div className='blog-wrapper blog-standard blog-details'>
                  <div className='blog-text'>
                    <h4>
                      <a href='/privacy-policy'>Privacy Policy</a>
                    </h4>

                    <div className='post-text mb-20'>
                      <div className='about-item mb-20'>
                        <h5>1. Introduction: </h5>
                        <p>
                          This policy outlines the handling of personal data by
                          the Community Promoting Foundation.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>2. Data Collection: </h5>
                        <p>
                          Information such as name, contact details, and
                          donation amounts are collected.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>3. Use of Information: </h5>
                        <p>
                          The collected data is used for donation processing,
                          communication, and legal compliance.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>4. Data Protection: </h5>
                        <p>
                          We are committed to securing personal data against
                          unauthorized access and misuse.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>5. Confidentiality: </h5>
                        <p>
                          Personal data is confidential and not shared
                          externally, except as required by law.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>6. Data Access: </h5>
                        <p>
                          Donors have rights to access and correct their
                          personal information.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>7. Policy Changes: </h5>
                        <p>
                          The Foundation may update this policy as necessary.
                        </p>
                      </div>
                      <div className='about-item mb-20'>
                        <h5>8. Contact: </h5>
                        <p>
                          For inquiries regarding this policy, please contact us
                          at{' '}
                          <a href='mailto:info@cpfthailand.org'>
                            info@cpfthailand.org
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default PrivacyPolicy;
