import { useEffect } from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";

const OurPlans = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log("Home");
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement("script");
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName("body")[0].appendChild(tag);
    };
    loadScript("../assets/js/vendor/modernizr-3.5.0.min.js");
    loadScript("../assets/js/vendor/jquery-1.12.4.min.js");
    loadScript("../assets/js/popper.min.js");
    loadScript("../assets/js/bootstrap.min.js");
    loadScript("../assets/js/owl.carousel.min.js");
    loadScript("../assets/js/isotope.pkgd.min.js");
    loadScript("../assets/js/slick.min.js");
    loadScript("../assets/js/jquery.meanmenu.min.js");
    loadScript("../assets/js/ajax-form.js");
    loadScript("../assets/js/wow.min.js");
    loadScript("../assets/js/jquery.knob.js");
    loadScript("../assets/js/jquery.appear.js");
    loadScript("../assets/js/waypoints.min.js");
    loadScript("../assets/js/jquery.counterup.min.js");
    loadScript("../assets/js/jquery.scrollUp.min.js");
    loadScript("../assets/js/imagesloaded.pkgd.min.js");
    loadScript("../assets/js/jquery.magnific-popup.min.js");
    loadScript("../assets/js/plugins.js");
    loadScript("../assets/js/main.js");

    return () => {
      document.getElementsByTagName("body")[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className="breadcrumb-area pt-150 pb-150"
          data-overlay="7"
          style={{
            backgroundImage: "url(" + "assets/img/bg/page-title.webp" + ")",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="breadcrumb-text text-center z-index">
                  <h1>Our Plans</h1>
                  <ul className="breadcrumb-menu">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <span>Our Plans</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-area pt-20 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 mb-30">
                <div className="blog-wrapper blog-standard blog-details">
                  <div className="blog-text">
                    <h4>
                      <a href="/our-plans">Our Project Plans</a>
                    </h4>

                    <div className="post-text mb-20">
                      <p>
                        We're actively shaping a brighter future. Join us in
                        making a difference.
                      </p>
                      <h4>Educational Seminar for All </h4>
                      <p>
                        <b>Objective:</b> Empower students, teachers, and
                        parents with valuable life skills and mental health
                        knowledge.
                      </p>
                      <h4>Components: </h4>
                      <div className="about-item mb-20">
                        <h5>1. Inspirational Speaker Sessions: </h5>
                        <ul>
                          <li>
                            <div className="about-item-text">
                              <span>
                                Motivational talks on growth, confidence, and
                                resilience for students, teachers, and parents.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="about-item mb-20">
                        <h5>2. Wellness Workshops: </h5>
                        <ul>
                          <li>
                            <div className="about-item-text">
                              <span>
                                Empowering health and well-being by addressing
                                NCD prevention, and offering sessions on
                                nutrition, sleep, exercise, and mental health.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="about-item mb-20">
                        <h5>3. Expert Panel Discussions: </h5>
                        <ul>
                          <li>
                            <div className="about-item-text">
                              <span>
                                Engaging conversations on children's health and
                                well-being.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="about-item mb-20">
                        <h5>4. Interactive Sessions: </h5>
                        <ul>
                          <li>
                            <div className="about-item-text">
                              <span>
                                Group activities and shared experiences to
                                foster a supportive community.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="about-item mb-20">
                        <h5>5. Resource Materials: </h5>
                        <ul>
                          <li>
                            <div className="about-item-text">
                              <span>
                                Informative guides and online resources for
                                continuous learning.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="about-item mb-20">
                        <h5>6. Follow-Up Support: </h5>
                        <ul>
                          <li>
                            <div className="about-item-text">
                              <span>
                                Ongoing channels for well-being and mental
                                health support.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="about-item mb-20">
                        <h5>7. Feedback and Evaluation: </h5>
                        <ul>
                          <li>
                            <div className="about-item-text">
                              <span>
                                Continuous improvement through participant
                                feedback.
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <p>
                        Our seminars aim to equip our community with essential
                        life skills and mental health knowledge, creating a
                        healthier and more informed society.
                      </p>
                      <p>Your support can help us achieve these goals.</p>
                      {/* <p>
                        If you'd like to be a part of our mission, please let us
                        know through the form below.
                      </p> */}
                      <p>If you're interested in joining our mission, kindly email us at <a href = "mailto:info@cpfthailand.org">info@cpfthailand.org</a>.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default OurPlans;
