import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const Team = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + 'assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Our Team</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Team</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='about-area pt-20 pb-50'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-md-6'>
                <div className='team mb-30'>
                  <div
                    className='team-img'
                    style={{ border: '1px solid #f6f6f6' }}
                  >
                    <img src='assets/img/team/chuthigran-dongird.webp' alt='' />
                  </div>
                  <div className='team-content'>
                    <span>President</span>
                    <h3>
                      <a href='/chuthigran-dongird'>Chuthigran Dongird</a>
                    </h3>
                    <div className='team-social'>
                      <ul>
                        <li>
                          <a href='/chuthigran-dongird'>
                            <i className='fab fa fa-link'></i>{' '}
                            <span>Read more</span>
                          </a>
                        </li>
                        {/* <li>
                          <a href='#'>
                            <i className='fab fa-twitter'></i>{' '}
                            <span>twitter.com/miranda7600</span>
                          </a>
                        </li>
                        <li>
                          <a href='#'>
                            <i className='fal fa-envelope'></i>{' '}
                            <span>info@webexample.com</span>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6'>
                <div className='team mb-30'>
                  <div
                    className='team-img'
                    style={{ border: '1px solid #f6f6f6' }}
                  >
                    <img
                      src='assets/img/team/pimchanit-mingphan.webp'
                      alt='Pimchanit Mingphan'
                    />
                  </div>
                  <div className='team-content'>
                    <span>Executive Board</span>
                    <h3>
                      <a href='#'>Pimchanit Mingphan</a>
                    </h3>
                    <div className='team-social'>
                      <ul>
                        <li>
                          {/* <a href='/chuthigran-dongird'>
                            <i className='fab fa fa-link'></i>{' '}
                            <span>Read more</span>
                          </a> */}
                        </li>
                        {/* <li>
                          <a href='#'>
                            <i className='fab fa-twitter'></i>{' '}
                            <span>twitter.com/miranda7600</span>
                          </a>
                        </li>
                        <li>
                          <a href='#'>
                            <i className='fal fa-envelope'></i>{' '}
                            <span>info@webexample.com</span>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-4 col-md-6'>
                <div className='team mb-30'>
                  <div
                    className='team-img'
                    style={{ border: '1px solid #f6f6f6' }}
                  >
                    <img
                      src='assets/img/team/sunthorn-chavalchotewute.webp'
                      alt='Sunthorn Chavalchotewute'
                    />
                  </div>
                  <div className='team-content'>
                    <span>Executive Board</span>
                    <h3>
                      <a href='#'>Sunthorn Chavalchotewute</a>
                    </h3>
                    <div className='team-social'>
                      <ul>
                        <li>
                          {/* <a href='/chuthigran-dongird'>
                            <i className='fab fa fa-link'></i>{' '}
                            <span>Read more</span>
                          </a> */}
                        </li>
                        {/* <li>
                          <a href='#'>
                            <i className='fab fa-twitter'></i>{' '}
                            <span>twitter.com/miranda7600</span>
                          </a>
                        </li>
                        <li>
                          <a href='#'>
                            <i className='fal fa-envelope'></i>{' '}
                            <span>info@webexample.com</span>
                          </a>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Team;
