import { useEffect } from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';

const SupportingThaiYouthsMentalHealth = () => {
  useEffect(() => {
    runJQueryScripts();
    console.log('Home');
  }, []);

  const runJQueryScripts = () => {
    let tag: any;
    let loadScript = function (src: string) {
      tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body')[0].appendChild(tag);
    };
    loadScript('../assets/js/vendor/modernizr-3.5.0.min.js');
    loadScript('../assets/js/vendor/jquery-1.12.4.min.js');
    loadScript('../assets/js/popper.min.js');
    loadScript('../assets/js/bootstrap.min.js');
    loadScript('../assets/js/owl.carousel.min.js');
    loadScript('../assets/js/isotope.pkgd.min.js');
    loadScript('../assets/js/slick.min.js');
    loadScript('../assets/js/jquery.meanmenu.min.js');
    loadScript('../assets/js/ajax-form.js');
    loadScript('../assets/js/wow.min.js');
    loadScript('../assets/js/jquery.knob.js');
    loadScript('../assets/js/jquery.appear.js');
    loadScript('../assets/js/waypoints.min.js');
    loadScript('../assets/js/jquery.counterup.min.js');
    loadScript('../assets/js/jquery.scrollUp.min.js');
    loadScript('../assets/js/imagesloaded.pkgd.min.js');
    loadScript('../assets/js/jquery.magnific-popup.min.js');
    loadScript('../assets/js/plugins.js');
    loadScript('../assets/js/main.js');

    return () => {
      document.getElementsByTagName('body')[0].removeChild(tag);
    };
  };

  return (
    <>
      <Header />
      <main>
        <div
          className='breadcrumb-area pt-150 pb-150'
          data-overlay='7'
          style={{
            backgroundImage: 'url(' + '../assets/img/bg/page-title.webp' + ')',
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='breadcrumb-text text-center z-index'>
                  <h1>Supporting Thai Youth’s Mental Health</h1>
                  <ul className='breadcrumb-menu'>
                    <li>
                      <a href='/'>Home</a>
                    </li>
                    <li>
                      <span>Campaign</span>
                    </li>
                    <li>
                      <span>Supporting Thai Youth’s Mental Health</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='case-details pt-50 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className='case-details-thumb'>
                  <img
                    src='../assets/img/campaign/supporting-thai-youths-mental-health.webp'
                    alt=''
                  />
                </div>
                <div className='cd-info theme-bg'>
                  <div className='row'>
                    <div className='col-lg-12 col-md-12'>
                      <div className='cd-list  mb-15'>
                        {/* <span>Client</span> */}
                        <h3>Supporting Thai Youth’s Mental Health</h3>
                      </div>
                    </div>

                    {/* <div className='col-lg-3 col-md-6'>
                      <div className='cd-list text-lg-right mb-15'>
                        <a href='#'>
                          <i className='fab fa-facebook-f'></i>
                        </a>
                        <a href='#'>
                          <i className='fab fa-twitter'></i>
                        </a>
                        <a href='#'>
                          <i className='fal fa-envelope'></i>
                        </a>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className='case-details mt-20'>
                  <p>
                    <b>
                      The Community Promoting Foundation, in collaboration with
                      Raks Thai Foundation,
                    </b>{' '}
                    is committed to tackling the challenges and promoting
                    positive mental health among Thai youth. Innovations will be
                    implemented to create positive impacts, enhance knowledge,
                    and develop physical and mental well-being, fostering a
                    society with improved mental health.
                  </p>
                  <p>
                    The current state of mental health in Thai society is a
                    pressing issue, with a suicide rate among youth aged 13-17
                    reaching a concerning 17.6%. This is primarily attributed to
                    depressive conditions, making it the third leading cause of
                    death among Thai youth. The problem has escalated in recent
                    years, reaching its peak in 2025.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-5'>
                <div className='sycho-tab-img mb-30'>
                  <img
                    src='../assets/img/campaign/collaborates-with-the-community-promoting-foundation.webp'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-xl-6 col-lg-7'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='section-title mb-40'>
                    <h2>Collaborates with Raks Thai Foundation</h2>
                  </div>
                  <div className='about-item mb-20'>
                    <p>
                      To address these challenges, we have planned and developed
                      projects related to mental health, focusing on both direct
                      and indirect interventions for youth and those connected
                      to them.
                    </p>
                    <p>
                      Raks Thai Foundation collaborates with the Community
                      Promoting Foundation to develop projects that enhance
                      communication, aiming to improve youth health through
                      lifestyle changes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-7 order-2 order-lg-1'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='section-title mb-40'>
                    <h2>Main Objectives</h2>
                  </div>
                  <div className='about-item mb-20'>
                    <p>
                      Create innovations to develop the mental health of Thai
                      youth. Educate youth about physical and mental health,
                      with a focus on non-communicable diseases (NCDs).
                      Cultivate a healthier society by reducing illness rates
                      and increasing health knowledge, particularly in the area
                      of youth mental health.
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-xl-6 col-lg-5 order-1 order-lg-2'>
                <div className='sycho-tab-img mb-30'>
                  <img
                    src='../assets/img/campaign/main-objectives.webp'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-5'>
                <div className='sycho-tab-img mb-30'>
                  <img
                    src='../assets/img/campaign/key-components-of-the-project.webp'
                    alt=''
                  />
                </div>
              </div>
              <div className='col-xl-6 col-lg-7'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='section-title mb-40'>
                    <h2>Key Components of the Project</h2>
                  </div>
                  <div className='about-item mb-20'>
                    <p>
                      <b>☉ Involvement of Experts</b> <br></br>
                      Collaboration with medical professionals and a dedicated
                      team. Development of strategies to improve youth health
                      through lifestyle changes.
                    </p>
                    <p>
                      <b>☉ Health Education</b> <br></br>
                      Providing knowledge to students about the impact of
                      nutrition, physical activity, and sleep on mental health.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-6 col-lg-7 order-2 order-lg-1'>
                <div className='sycho-tab-content pt-0 pr-0 mb-30'>
                  <div className='section-title mb-40'>
                    <h2>Skills necessary for daily life</h2>
                  </div>
                  <div className='about-item mb-20'>
                    <p>
                      <b>☉ Stress Management Skills</b> <br></br>
                      Techniques to reduce stress, anxiety, and depression.
                    </p>
                    <p>
                      <b>☉ Promotion of Healthy Habits</b> <br></br>
                      Emphasizing the importance of discipline for good health.
                    </p>
                    <p>
                      <b>☉ Essential Life Skills</b> <br></br>
                      Focusing on teaching crucial skills necessary for daily
                      life, preparing youth to face diverse challenges
                      effectively.
                    </p>
                  </div>
                </div>
              </div>

              <div className='col-xl-6 col-lg-5 order-1 order-lg-2'>
                <div className='sycho-tab-img mb-30'>
                  <img
                    src='../assets/img/campaign/stress-management-skills.webp'
                    alt=''
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='about-area pt-20 pb-20'>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12 col-lg-12'>
                <div className='sycho-tab-content pt-20 pr-0 mb-20'>
                  <div className='section-title mb-20'>
                    <p>
                      Raks Thai Foundation has organized seminars on youth
                      mental health in schools to transfer knowledge about
                      physical and mental health to the targeted group of 10
                      schools in Ayutthaya province.
                    </p>
                    <p>
                      <b>
                        ♡ If any organization is interested in supporting the
                        "Impacting Thai Youth's Mental Health" project,{' '}
                      </b>{' '}
                      <br></br>
                      Fundraising Duration : 29th Feb – 29th May 2024
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-4 col-lg-4 col-md-6'>
                <div className='row'>
                  <div className='col-xl-6 col-lg-6 col-md-6'>
                    <a
                      href='/get-involved'
                      className='c-btn'
                      style={{
                        padding: '22px 30px 22px 30px',
                        fontSize: 14,
                        marginBottom: 10,
                      }}
                    >
                      Donate Now{' '}
                    </a>
                  </div>
                  {/* <div className='col-xl-6 col-lg-6 col-md-6'>
                    <a
                      style={{
                        padding: '10px 14px 10px 14px',
                        fontSize: 14,
                        marginBottom: 10,
                      }}
                      href='/campaign/supporting-thai-youths-mental-health'
                      className='c-btn border-btn'
                    >
                      Learn More
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default SupportingThaiYouthsMentalHealth;
